<template>
  <footer-mobile-navigation-calanhad
    v-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Calanhad'"
  />
  <footer-mobile-navigation-grey-havens
    v-else-if="appConfig.VueSettingsPreRun.HeaderLayout === 'GreyHavens'"
  />
  <footer-mobile-navigation-bar v-else />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
